<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div class="action-item">
            <div v-for="(item, ind) in value.value" :key="ind">
              <div v-if="item.roleOwner == true">
                <div v-if="user_type == typeOwner || user_type == typeCDEA">
                  <router-link class="d-flex" :to="{ name: item.routeName }">
                    <img :src="item.icon" alt="" class="my-auto" />
                    <p class="ml-2 my-auto">{{ item.text }}</p>
                  </router-link>
                </div>
              </div>
              <div v-else>
                <router-link class="d-flex" :to="{ name: item.routeName }">
                  <img :src="item.icon" alt="" class="my-auto" />
                  <p class="ml-2 my-auto">{{ item.text }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import generalMenu from "@/assets/img/general.png";
import snsMenu from "@/assets/img/SNS.png";
import designSetting from "@/assets/img/design.png";
import paymentMenu from "@/assets/img/payment.png";
import saleSettingMenu from "@/assets/img/sale_setting.png";
import storeSettingMenu from "@/assets/img/store_setting.png";
import emailSettingMenu from "@/assets/img/email_setting.png";
// import authorMenu from "@/assets/img/author.png";
// import menusMenu from "@/assets/img/menu_setting.png";
// import pointSettingMenu from "@/assets/img/point_setting.png";
import { Constants } from "../../utils/constants";

export default {
  name: "DashboardAdminEdit",
  data() {
    return {
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      listFunction: [
        {
          title: "一般デフォルト設定",
          value: [
            {
              icon: generalMenu,
              text: "一般設定",
              routeName: "General Setting",
              roleOwner: true,
            },
            {
              icon: snsMenu,
              text: "SNS設定",
              routeName: "SNS Setting",
              roleOwner: true,
            },
            {
              icon: designSetting,
              text: "デザイン設定",
              routeName: "design setting",
              roleOwner: true,
            },
          ],
        },
        {
          title: "販売デフォルト設定",
          value: [
            {
              icon: paymentMenu,
              text: "決済情報登録",
              routeName: "paymentSetting",
              roleOwner: true,
            },
            {
              icon: saleSettingMenu,
              text: "販売情報設定",
              routeName: "Sales Setting",
              roleOwner: true,
            },
            {
              icon: storeSettingMenu,
              text: "ストア設定",
              routeName: "store setting",
            },
          ],
        },
        {
          title: "メールデフォルト設定",
          value: [
            {
              icon: emailSettingMenu,
              text: "メール設定",
              routeName: "email setting",
            },
          ],
        },
        // {
        //   title: "著者管理",
        //   value: [
        //     {
        //       icon: authorMenu,
        //       text: "著者管理",
        //       routeName: "ListAuthor",
        //     },
        //   ],
        // },
        // {
        //   title: "メニュー",
        //   value: [
        //     {
        //       icon: menusMenu,
        //       text: "メニュー設定",
        //       routeName: "menuManager",
        //       roleOwner: true,
        //     },
        //   ],
        // },
        // {
        //   title: "ポイント機能",
        //   value: [
        //     {
        //       icon: pointSettingMenu,
        //       text: "ポイント設定",
        //       routeName: "list point",
        //       roleOwner: true,
        //     },
        //     // {
        //     //   icon: iconDefault,
        //     //   text: "ポイント定義",
        //     //   routeName: "Create Point Definition",
        //     //   roleOwner: true,
        //     // },
        //   ],
        // },
      ],
    };
  },
  created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          if (e.routeName != null) {
            e.routeName += " domain";
          }
        });
      });
    }
  },
};
</script>
